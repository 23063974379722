


/*===========================
    10.APPIE FOOTER css
===========================*/

.appie-footer-area{
  background: $grey;
  padding-top: 30px;
  padding-bottom: 30px;
  &.appie-footer-3-area{
    padding-top: 180px;
    &.appie-footer-5-area{
      background: $white;
    }
  }
  &.appie-footer-about-area{
    padding-top: 230px;
    &.appie-footer-8-area{
      background: $white;
      & .footer-about-widget{
        & .social{
          & ul{
            & li{
              & a{
                border: 2px solid #e6e7ea;
                &:hover{
                  background: $theme-color-7;
                  border-color: $theme-color-7;
                  color: $white;
                }
              }
            }
          }
        }
      }
      & .footer-navigation{
        & ul{
          & li{
            & a{
              &:hover{
                color: $theme-color-7;
              }
            }
          }
        }
      }
      & .footer-widget-info{
        & ul{
          & li{
            & a{
              & i{
                color: $theme-color-7;
              }
            }
          }
        }
      }
      & .footer-copyright{
        & .apps-download-btn{
          & ul{
            & li{
              & a{
                background: $heading-color;
                color: $white;
                border-color: $heading-color;
                &:hover{
                  background: $white;
                  color: $heading-color;
                }
                &.item-2{
                  background: $white;
                  color: $heading-color;
                  &:hover{
                    background: $heading-color;
                    color: $white;
                    border-color: $heading-color;

                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.appie-footer-9-area{

    .footer-about-widget .social ul li a:hover {
      background: $theme-color-8;
    }

    .footer-navigation ul li a:hover {
      color: $theme-color-8;
    }

    .footer-widget-info ul li a i{
      color: $theme-color-8;
    }
  }
}



.footer-about-widget{
  padding-right: 70px;
  margin-top: 30px;
  @media #{$xs} {
    text-align: center;
    padding-right: 0;
  }
  & p{
    padding-top: 25px;
  }
  & > a{
    font-size: 15px;
    font-weight: 500;
    color: $heading-color;
    margin-top: 15px;
    & i{
      padding-left: 6px;
    }
  }
  & .social{
    & ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
      & li{
        display: inline-block;
        & a{
          height: 36px;
          width: 36px;
          text-align: center;
          background: $white;
          color: $heading-color;
          border-radius: 6px;
          line-height: 36px;
          margin-right: 5px;
          &:hover{
            background: $theme-color;
            color: $white;
          }
        }
      }
    }
  }
  &.footer-about-widget-2{
    & ul{
      & li{
        & a{
          &:hover{
            background: $theme-color-2;
          }
        }
      }
    }
  }
  &.footer-about-widget-3{
    & ul{
      & li{
        & a{
          &:hover{
            background: $theme-color-3;
          }
        }
      }
    }
  }
  &.footer-about-widget-6{
    & ul{
      & li{
        & a{
          &:hover{
            background: $theme-color-6;
          }
        }
      }
    }
  }
  &.footer-about-widget-5{
    & ul{
      & li{
        & a{
          &:hover{
            background: $theme-color-4;
          }
        }
      }
    }
  }
}


.footer-navigation{
  margin-top: 30px;
  @media #{$xs} {
    text-align: center;
  }
  & .title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  & ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    & li{
      & a{
        color: $text-color;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 6px;
        &:hover{
          color: $theme-color;
        }
      }
    }
  }
  &.footer-navigation-2{
    & ul{
      & li{
        & a{
          &:hover{
            color: $theme-color-2;
          }
        }
      }
    }
  }
  &.footer-navigation-3{
    & ul{
      & li{
        & a{
          &:hover{
            color: $theme-color-3;
          }
        }
      }
    }
  }
  &.footer-navigation-6{
    & ul{
      & li{
        & a{
          &:hover{
            color: $theme-color-6;
          }
        }
      }
    }
  }
  &.footer-navigation-5{
    & ul{
      & li{
        & a{
          &:hover{
            color: $theme-color-4;
          }
        }
      }
    }
  }
}


.footer-widget-info{
  margin-top: 30px;
  @media #{$xs} {
    text-align: center;
  }
  & .title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  & ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    & li{
      & a{
        color: $text-color;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;
        & i{
          position: absolute;
          left: 0;
          top: 0;
          color: $theme-color;
        }
      }
    }
  }
}


.footer-copyright{
  padding-top: 35px;
  border-top: 1px solid #e1e5ec;
  margin-top: 80px;
  @media #{$md} {
    display: block !important;
    text-align: center;

  }
  @media #{$xs} {
    display: block !important;
    text-align: center;

  }
  & .apps-download-btn{
    @media #{$md} {
      margin-bottom: 20px;
    }
    @media #{$xs} {
      margin-bottom: 20px;
    }
    & ul{
      padding: 0;
      list-style-type: none;
      & li{
        display: inline-block;
        margin-right: 8px;
        @media #{$xs} {
          margin-bottom: 20px;
        }
        @media #{$sm} {
          margin-bottom: 0px;
        }
        & a{
          background: $white;
          color: $heading-color;
          line-height: 40px;
          padding: 0 23px;
          border-radius: 6px;
          border: 2px solid $white;
          & i{
            padding-right: 6px;
          }
          &:hover{
            background: transparent;
            color: $heading-color;
            border-color: #e7eaef;
          }
          &.item-2{
            background: transparent;
            color: $heading-color;
            border-color: #e7eaef;
            &:hover{
              background: $white;
              color: $heading-color;
            }
          }
        }
      }
    }
  }
}







// appie error css


.appie-error-area{
  padding-top: 250px;
}



.appie-error-content{
  & span{
    display: block;
    font-size: 50px;
    color: #db0f30;
    font-weight: 800;
    padding-top: 40px;
  }
  & .title{
    font-size: 50px;
    font-weight: 800;
    color: $heading-color;
    padding-bottom: 11px;
  }
  & p{
    line-height: 26px;
    color: #505056;
    font-size: 16px;
    padding: 0 50px;
  }
  & a{
    border: 2px solid $theme-color;
    line-height: 45px;
    padding: 0 35px;
    border-radius: 6px;
    margin-top: 30px;
  }
}


.appie-signup-area{
  &.appie-signup-8-area{
    background: #eef1f6;
    & .appie-signup-box{
      & form{
        & .input-box{
          & button{
            background: $theme-color-7;
          }
        }
      }
    }
  }

  &.appie-signup-9-area{

    background: none;
    margin-bottom: 120px;

    @media #{$md}{
      margin-bottom: 100px;
    }

    .appie-signup-box{
      background: url('../images/home-subs-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 0px;
    }

  }
}



.appie-signup-box{
  background-image: url(../images/signup-bg.jpg);
  padding: 63px 100px 60px;
  border-radius: 10px;
  box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: -160px;
  @media #{$md} {
    padding: 30px 50px;
  }
  @media #{$xs} {
    padding: 30px;
  }
  & .thumb{
    position: absolute;
    right: 70px;
    bottom: 30px;
    width: 160px;
    @media #{$md} {
      display: none;
    }
    @media #{$xs} {
      display: none;
    }
  }
  & span{
    color: $white;
    font-weight: 500;
  }
  & .title{
    font-size: 46px;
    color: $white;
    padding-bottom: 20px;
    @media #{$xs} {
      font-size: 30px;
    }
  }
  & form{
    display: flex;
    flex-wrap: wrap;
    @media #{$md} {
      flex-direction: column;
    }
    @media #{$xs} {
      flex-direction: column;
    }
    & .input-box{
      margin-right: 10px;
      margin-top: 10px;
      & input{
        width: 300px;
        background: $white;
        line-height: 55px;
        border-radius: 6px;
        padding-left: 30px;
        border: 0;
        @media #{$md} {
          width: 100%;
        }
        @media #{$xs} {
          width: 100%;
        }
      }
      & button{
        background: $theme-color;
        line-height: 55px;
        border: 0;
        color: $white;
        border-radius: 6px;
        padding: 0 45px;
        font-weight: 500;
      }
    }
  }
}

.appie_checkbox_common{
  margin-top: 15px;
}
.appie_checkbox_common  label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}
.appie_checkbox_common  label a{
  text-decoration: underline;
  color: #b3b6bc;
}

.appie_checkbox_common  input[type="checkbox"] {
  display: none;
}

.appie_checkbox_common  input[type="checkbox"]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.appie_checkbox_common  input[type="checkbox"]+label span:before {
  color: #fff;
  content: "\f00c";
  font-family: 'Font Awesome 5 Pro';
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -1px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.appie_checkbox_common  input[type="checkbox"]:checked+label span {
  background: #2079ff;
  border-color: #2079ff;
}

.appie_checkbox_common  input[type="checkbox"]:checked+label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.appie_checkbox_common  input[type="checkbox"]+label span,
.appie_checkbox_common input[type="checkbox"]:checked+label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.appie_checkbox_common.checkbox_style2  input[type="checkbox"] + label span {
  border-radius: 3px;
}

.appie-footer-10-area{

  .footer-about-widget{

    .social ul li a:hover{
      background: $theme-color;
    }
  }
  .footer-navigation{
    ul li a:hover{
      color: $theme-color;
    }
  }

}
