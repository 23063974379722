/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  scrollbar-color: #0E1133 #eef1f6!important;
  scrollbar-width: thin!important;
  --modal-editor__indent: 80px;
}

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";

/*-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/

@import 'theme/variables';
@import 'theme/mixin';
@import 'theme/common';
@import 'theme/header';
@import 'theme/appie-hero';
@import 'theme/appie-service';
@import 'theme/appie-features';
@import 'theme/appie-traffic';
@import 'theme/appie-testimonial';
@import 'theme/appie-team';
@import 'theme/appie-pricing';
@import 'theme/appie-faq';
@import 'theme/appie-blog';
@import 'theme/appie-project';
@import 'theme/appie-footer';
@import 'theme/appie-about';
@import 'theme/appie-counter';
@import 'theme/appie-video-player';
@import 'theme/appie-download';
@import 'theme/appie-fun-fact';
@import 'theme/appie-showcase';
@import 'theme/appie-contact';

@import "theme/slick/slick";

// default
@import "theme/default";
@import "theme/animations/custom-animation";
