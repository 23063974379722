


/*===========================
    13.APPIE VIDEO PLAYER css
===========================*/




.appie-video-player-slider{
  & .slick-arrow{
    position: absolute;
    right: 0;
    bottom: -90px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border: 2px solid #e3e5e8;
    color: $heading-color;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;
    &:hover{
      background: $theme-color-2;
      color: $white;
      border-color: $theme-color-2;
    }
    &.prev{
      right: 60px;
    }
    &.next{
      right: 0;
    }
  }
  & .item{
    img{
      width: 100%;
    }
  }
}

.appie-video-player-item{
  @media #{$md} {
    margin-bottom: 50px;
  }
  @media #{$xs} {
    margin-bottom: 50px;
  }
  & .thumb{
    position: relative;
    overflow: hidden;
    & img{
      width: 100%;
    }
    & .video-popup{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & a{
        height: 90px;
        width: 90px;
        background: $white;
        text-align: center;
        line-height: 90px;
        color: $theme-color-2;
        border-radius: 50%;
        font-size: 20px;
        position: relative;
        &::before{
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border: 1px solid #fff;
          border-radius: 50%;
          animation: linear infinite popup 1.5s;
        }

      }
    }
  }
  & .content{
    padding-left: 100px;
    padding-top: 35px;
    @media #{$lg} {
      padding-left: 30px;
    }
    @media #{$md} {
      padding-left: 0px;
    }
    @media #{$xs} {
      padding-left: 0px;
    }
    & .title{
      font-size: 30px;
      margin-bottom: 12px;
      @media #{$xs} {
        font-size: 26px;
      }
    }
    & a{
      border-radius: 30px;
      border: 2px solid #e7eaef;
      background: $white;
      color: $heading-color;
      margin-top: 33px;
      &:hover{
        background: $theme-color-2;
        border-color: $white;
        color: $white;
      }
    }
  }
}


@keyframes popup {
  0%   {
    transform: scale(1);
    opacity: 1;
  }
  25%  {
    transform: scale(1.1);
    opacity: 0.8;
  }
  50%  {
    transform: scale(1.2);
    opacity: 0.6;
  }
  75%  {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}



.appie-video-player-6-area{
  & .appie-video-player-item{
    & .content{
      & a{
        &:hover{
          background: $theme-color-6;
          border-color: $theme-color-6;
        }
      }
    }
  }
  & .appie-video-player-slider{
    & .slick-arrow{
      &:hover{
        background: $theme-color-6;
        border-color: $theme-color-6;
      }
    }
  }
}

