


/*===========================
    10.APPIE PROJECT css
===========================*/




.appie-project-area{
  & .appie-project-box{
    background-image: url(../images/project-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 70px;
    padding-top: 75px;
    padding-bottom: 85px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    @media #{$xs} {
      padding-left: 30px;
    }
    @media #{$sm} {
      padding-left: 70px;
    }
    & .appie-project-thumb{
      position: absolute;
      right: 70px;
      bottom: 0;
      @media #{$lg} {
        right: 30px;
      }
      @media #{$md} {
        display: none;
      }
      @media #{$xs} {
        display: none;
      }
    }
  }
}


.appie-project-content{
  padding-right: 50px;
  & .title{
    color: $white;
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 10px;
    @media #{$lg} {
      font-size: 36px;
      line-height: 46px;
    }
    @media #{$xs} {
      font-size: 30px;
      line-height: 40px;
    }
    @media #{$sm} {
      font-size: 36px;
      line-height: 46px;
    }
  }
  & p{
    color: $white;
    opacity: 0.8;
  }
  & .input-box{
    display: flex;
    @media #{$xs} {
      display: block;
    }
    @media #{$sm} {
      display: flex;
    }
    & input{
      width: 100%;
      height: 50px;
      padding-left: 30px;
      border-radius: 6px;
      border: 0;
    }
    & button{
      margin-left: 10px;
      padding: 0 30px;
      border-radius: 6px;
      padding: 0 25px;
      background: $heading-color;
      border: 0;
      color: $white;
      line-height: 50px;
      @media #{$xs} {
        margin-top: 20px;
        margin-left: 0;
      }
      @media #{$sm} {
        margin-top: 0px;
        margin-left: 10px;
      }
    }
  }
}





.appie-project-3-area{
  &.appie-project-5-area{
    background: $grey;
  }
}

.appie-project-3-box{
  background-image: url(../images/project-bg-2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 55px 70px;
  box-shadow: 0px 40px 50px 0px rgba(138, 16, 0, 0.3);
  margin-bottom: -110px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  @media #{$xs} {
    text-align: center;
  }
  & .title{
    color: $white;
    font-size: 46px;
    line-height: 56px;
    @media #{$md} {
      font-size: 36px;
      line-height: 46px;
    }
    @media #{$xs} {
      font-size: 35px;
      line-height: 46px;
      margin-bottom: 30px;
    }
  }
  & a{
    background: $white;
    color: $heading-color;
    border-color: $white;
    line-height: 55px;
    padding: 0 40px;
  }
}

.appie-project-10-area{
  .appie-project-3-box{
    background: url("../images/apppie-projects-9.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 40px 50px 0px rgba(7.0000000000000435, 121.00000000000017, 253.99999999999997, 0.21);
    a{
      border: none;
    }
  }
}

