/*===========================
    4.APPIE SERVOCE css
===========================*/

.appie-service-area {
  background: $grey;
  position: relative;
  background-image: url(../images/shape/shape-1.png);
  background-position: 92% 100%;
  background-repeat: no-repeat;
  background-size: auto;
  &.appie-service-3-area {
    background: none;
    @media #{$xs} {
      padding-top: 100px;
    }
    @media #{$sm} {
      padding-top: 190px;
    }
  }
}

.appie-single-service {
  padding: 50px 30px 43px;
  background: $white;
  border-radius: 6px;
  transition: all linear 0.3s;
  @media #{$lg} {
    padding: 50px 15px 43px;
  }
  & .icon {
    display: inline-block;
    border-radius: 20px;
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    background: linear-gradient(
                    135deg,
                    rgba(132, 188, 250, 1) 0%,
                    rgba(43, 112, 250, 1) 100%
    );
    box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
    position: relative;
    & > span {
      position: absolute;
      right: -10px;
      top: -10px;
      height: 30px;
      width: 30px;
      background: #2b70fa;
      border-radius: 50%;
      border: 2px solid $white;
      box-shadow: 0px 3px 6px 0px rgba(14, 17, 51, 0.1);
      line-height: 28px;
      color: $white;
    }
  }
  & .appie-title {
    font-size: 20px;
    font-weight: 700;
    padding-top: 33px;
    padding-bottom: 10px;
  }
  &:hover {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    transform: translateY(-15px);
  }
  &.item-2 {
    & .icon {
      background: linear-gradient(
                      135deg,
                      rgba(247, 184, 175, 1) 0%,
                      rgba(248, 69, 45, 1) 100%
      );
      & span {
        background: #f8452d;
      }
    }
  }
  &.item-3 {
    & .icon {
      background: linear-gradient(
                      135deg,
                      rgba(160, 234, 194, 1) 0%,
                      rgba(49, 195, 105, 1) 100%
      );
      & span {
        background: #31c369;
      }
    }
  }
  &.item-4 {
    & .icon {
      background: linear-gradient(
                      135deg,
                      rgba(216, 170, 255, 1) 0%,
                      rgba(155, 44, 250, 1) 100%
      );
      & span {
        background: #9b2cfa;
      }
    }
  }
  &.appie-single-services-3 {
    background: transparent;
    & .icon {
      border-radius: 50%;
      background-image: -moz-linear-gradient(
                      110deg,
                      rgb(255, 95, 74) 0%,
                      rgb(255, 158, 145) 100%
      );
      background-image: -webkit-linear-gradient(
                      110deg,
                      rgb(255, 95, 74) 0%,
                      rgb(255, 158, 145) 100%
      );
      background-image: -ms-linear-gradient(
                      110deg,
                      rgb(255, 95, 74) 0%,
                      rgb(255, 158, 145) 100%
      );
    }
    & a {
      color: $text-color;
      font-size: 14px;
      font-weight: 500;
      margin-top: 25px;
      &:hover {
        color: $theme-color-3;
      }
    }
    &.item-2 {
      background: linear-gradient(
                      0deg,
                      rgba(39, 153, 246, 0.08) 0%,
                      rgba(255, 255, 255, 0.08) 100%
      );
      & .icon {
        background-image: -moz-linear-gradient(
                        110deg,
                        rgb(39, 153, 246) 0%,
                        rgb(118, 193, 255) 100%
        );
        background-image: -webkit-linear-gradient(
                        110deg,
                        rgb(39, 153, 246) 0%,
                        rgb(118, 193, 255) 100%
        );
        background-image: -ms-linear-gradient(
                        110deg,
                        rgb(39, 153, 246) 0%,
                        rgb(118, 193, 255) 100%
        );
      }
    }
    &.item-3 {
      & .icon {
        background-image: -moz-linear-gradient(
                        110deg,
                        rgb(242, 51, 91) 0%,
                        rgb(255, 113, 143) 100%
        );
        background-image: -webkit-linear-gradient(
                        110deg,
                        rgb(242, 51, 91) 0%,
                        rgb(255, 113, 143) 100%
        );
        background-image: -ms-linear-gradient(
                        110deg,
                        rgb(242, 51, 91) 0%,
                        rgb(255, 113, 143) 100%
        );
      }
    }
    &.item-4 {
      background: linear-gradient(
                      0deg,
                      rgba(119, 89, 237, 0.08) 0%,
                      rgba(255, 255, 255, 0.08) 100%
      );
      & .icon {
        background-image: -moz-linear-gradient(
                        110deg,
                        rgb(119, 89, 237) 0%,
                        rgb(172, 151, 255) 100%
        );
        background-image: -webkit-linear-gradient(
                        110deg,
                        rgb(119, 89, 237) 0%,
                        rgb(172, 151, 255) 100%
        );
        background-image: -ms-linear-gradient(
                        110deg,
                        rgb(119, 89, 237) 0%,
                        rgb(172, 151, 255) 100%
        );
      }
    }
    &.appie-single-services-5 {
      & a {
        &:hover {
          color: $theme-color-4;
        }
      }
    }
  }
}

.appie-services-2-area {
  & .appie-section-title {
    @media #{$xs} {
      text-align: center !important;
    }
  }
}

.appie-single-service-2 {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  padding: 48px;
  @include transition(0.3s);
  @media #{$xs} {
    text-align: center;
    padding: 20px;
  }
  & .icon {
    & i {
      height: 50px;
      width: 50px;
      text-align: center;
      line-height: 50px;
      background: #2eacfc;
      color: $white;
      border-radius: 50%;
    }
  }
  & .title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  & a {
    font-size: 14px;
    font-weight: 500;
    color: $text-color;
    margin-top: 23px;
    &:hover {
      color: $theme-color-2;
    }
  }
  &:hover {
    box-shadow: 0px -3px 0px 0px rgba(234, 76, 137, 0.004),
    0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    border-top: 3px solid #2eacfc;
    transform: translateY(-10px);
  }
  &.item-2 {
    & .icon {
      & i {
        background: #ea4c89;
      }
    }
    &:hover {
      border-top-color: #ea4c89;
    }
  }
  &.item-3 {
    & .icon {
      & i {
        background: #3578ff;
      }
    }
    &:hover {
      border-top-color: #3578ff;
    }
  }
  &.item-4 {
    & .icon {
      & i {
        background: #f67741;
      }
    }
    &:hover {
      border-top-color: #f67741;
    }
  }
  &.item-5 {
    & .icon {
      & i {
        background: #6a88f7;
      }
    }
    &:hover {
      border-top-color: #6a88f7;
    }
  }
  &.item-6 {
    & .icon {
      & i {
        background: #3ab094;
      }
    }
    &:hover {
      border-top-color: #3ab094;
    }
  }
  &.appie-single-service-about {
    border-radius: 0;
    padding: 0 90px 30px 0;
    border: 0;
    transition: all linear 0.3s;
    @media #{$lg} {
      padding-right: 30px;
    }
    @media #{$xs} {
      padding-right: 50px;
      padding-left: 50px;
    }
    & .icon {
      & i {
        background-color: #f1f7fe;
        color: #2b70fa;
        border-radius: 35% 65% 50% 50% / 59% 50% 50% 41%;
        font-size: 20px;
      }
    }
    &:hover {
      box-shadow: none;
      margin-top: 20px;
    }
    &.item-2 {
      & .icon {
        & i {
          color: #db0f30;
          background-color: #fef3f5;
          border-radius: 50% 50% 50% 50% / 50% 75% 25% 50%;
        }
      }
    }
    &.item-3 {
      & .icon {
        & i {
          color: #9b2cfa;
          background-color: #f7eeff;
          border-radius: 25% 75% 50% 50% / 50% 50% 50% 50%;
        }
      }
    }
    &.item-4 {
      & .icon {
        & i {
          color: #f8452d;
          background-color: #fef0ee;
          border-radius: 40% 60% 40% 60% / 40% 60% 40% 60%;
        }
      }
    }
    &.item-5 {
      & .icon {
        & i {
          color: #31c369;
          background-color: #f4fcf7;
          border-radius: 33% 67% 50% 50% / 61% 72% 28% 39%;
        }
      }
    }
    &.item-6 {
      & .icon {
        & i {
          color: #f6ac0e;
          background-color: #fef7e7;
          border-radius: 21% 79% 58% 42% / 50% 46% 54% 50%;
        }
      }
    }
  }
}

.appie-services-6-area {
  & .appie-section-title {
    & .main-btn {
      &:hover {
        background: $theme-color-6;
        border-color: $theme-color-6;
      }
    }
  }
  & .appie-single-service-2 {
    & a {
      &:hover {
        color: $theme-color-6;
      }
    }
  }
}

.service-details-sidebar {
  @media #{$lg} {
    margin-right: 0;
  }
  @media #{$md} {
    margin-right: 0;
    margin-bottom: 30px;
  }
  @media #{$xs} {
    margin-right: 0;
    margin-bottom: 30px;
  }
  & .service-category-widget {
    border-radius: 6px;
    box-shadow: 0px 30px 50px 0px rgba(14, 17, 51, 0.1);
    padding: 30px;
    margin-bottom: 30px;
    & ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      & li {
        margin-bottom: 20px;
        & i {
          color: $theme-color;
          width: 15px;
          margin-right: 15px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  & .service-download-widget {
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0px 0px 50px 0px rgba(14, 17, 51, 0.1);
    & a {
      padding: 13px 30px;
      color: $text-color;
      &:hover {
        color: $theme-color;
      }
    }
    & i {
      color: $theme-color;
      padding-right: 15px;
    }
  }
}

.service-details-content {
  & .thumb {
    border-radius: 10px;
    overflow: hidden;
  }
  & .content {
    padding-top: 34px;
    & .title {
      font-size: 30px;
      margin-bottom: 18px;
    }
    & span {
      font-size: 20px;
      line-height: 30px;
      color: $heading-color;
      padding-top: 34px;
      padding-bottom: 26px;
    }
  }
}

.appie-services-8-area {
  & .service-thumb {
    margin-left: -50px;
    margin-right: -100px;
    @media #{$md} {
      display: none;
    }
    @media #{$xs} {
      display: none;
    }
  }
}

.appie-services-9-area {
  background: none;
  @media #{$md}{
    padding-bottom: 60px;
  }

  .appie-single-service.appie-single-services-9 {
    color: #505056;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background-color: #f8f8f8;
    border: 0;
    padding: 33px 30px 53px 30px;

    .icon {
      background: none !important;
      box-shadow: none;
      width: auto !important;
      height: auto !important;

      img {
        width: 100px;
        height: 100px;
      }

      span {
        display: none;
      }
    }

    .appie-title {
      padding-top: 21px;
    }
  }
}

.appie-service-10-area {
  padding-top: 110px;
  padding-bottom: 20px;
  background: none;

  @media #{$md}{
    padding-top: 90px;
    padding-bottom: 20px;
  }
  @media #{$xs}{
    padding-top: 90px;
    padding-bottom: 20px;
  }

}

.appie-service-2-10-area {
  padding-top: 110px;
  padding-bottom: 120px;

  @media #{$md}{
    padding-top: 90px;
    padding-bottom: 100px;
  }

  @media #{$xs}{
    padding-top: 90px;
    padding-bottom: 100px;
  }

}