/*===========================
    5.APPIE FEATURES css
===========================*/

.appie-features-area {
  position: relative;
  z-index: 10;
  overflow: hidden;
  & .features-shape-1 {
    position: absolute;
    left: 560px;
    top: 130px;
    animation: animationFramesOne 20s linear infinite;
    z-index: -1;
  }
  & .features-shape-2 {
    position: absolute;
    left: 500px;
    bottom: 0px;
    animation: animationFramesThree 20s linear infinite;
    z-index: -1;
  }
  & .features-shape-3 {
    position: absolute;
    right: 270px;
    bottom: 0;
    animation: animationFramesFive 20s linear infinite;
    z-index: -1;
  }
}

.appie-features-9-area {
  padding-top: 120px;

  @media #{$md}{
    padding-top: 95px;
  }
  @media #{$xs}{
    padding-top: 95px;
  }

  .appie-features-9-area-heading {
    margin-bottom: 48px;
    span {
      font-size: 25px;
      color: $theme-color-9;
      font-weight: 500;
      @media #{$md}{
        font-size: 22px;
        line-height: 28px;
      }
      @media #{$xs}{
        font-size: 18px;
        line-height: 26px;
      }
    }
    h2 {
      font-size: 44px;
      line-height: 50px;
      color: #0e1133;
      font-weight: 900;
      margin-top: 10px;

      @media #{$md}{
        font-size: 36px;
        line-height: 46px;
      }
      @media #{$xs}{
        font-size: 26px;
        line-height: 36px;
      }

    }
  }

  .appie-features-9-area-thumb{
    margin-left: -140px;
    @media #{$lg}{
      margin-left: 0px;
      text-align: center;
    }
    @media #{$md}{
      margin-left: 0px;
      text-align: center;
    }
    @media #{$xs}{
      margin-left: 0px;
      text-align: center;
    }


    img{
      max-width: 100%;
    }

  }
}


.appie-features-tabs-btn {
  & .nav {
    @media #{$md} {
      flex-direction: row !important;
      justify-content: center;
      margin-bottom: 40px;
    }
    @media #{$xs} {
      flex-direction: row !important;
      justify-content: center;
      margin-bottom: 40px;
    }
    & a {
      border-right: 3px solid #e8eaef;
      border-radius: 0;
      font-size: 15px;
      font-weight: 500;
      color: $text-color;
      @media #{$md} {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media #{$xs} {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      & i {
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 35px;
        background: #f1f2f7;
        color: #63636a;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
      }
      &.active {
        border-right-color: $theme-color;
        background: transparent;
        color: $theme-color;
        & i {
          color: $white;
          background: $theme-color;
        }
      }
    }
  }

  &.appie-features-9-tabs-btn{

    & a {
      color: #63636a;
      border-bottom: 3px solid #e8eaef;
      border-right: none;
      padding-right: 30px;
      padding-bottom: 15px;
      @media #{$md} {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media #{$xs} {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      & i {
        margin-right: 0px;
        margin-left: 10px;
      }
      &.active {
        border-bottom-color: $theme-color-8;
        background: transparent;
        color: $theme-color-8;
        & i {
          color: $white;
          background: $theme-color-8;
        }
      }
    }
  }
}



.appie-features-content {
  @media #{$md} {
    padding-top: 40px;
  }
  @media #{$xs} {
    padding-top: 30px;
  }
  & span {
    font-size: 14px;
    font-weight: 500;
    color: $theme-color;
    margin-bottom: 8px;
  }
  & .title {
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 10px;
    @media #{$lg} {
      font-size: 36px;
      line-height: 44px;
    }
    @media #{$xs} {
      font-size: 30px;
      line-height: 40px;
    }
  }
  & p {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 30px;
  }

}

.appie-features-area-2 {
  background: $heading-color;
  position: relative;
  overflow: hidden;
  z-index: 10;
  & .features-shape-1 {
    position: absolute;
    left: 240px;
    top: 240px;
    z-index: -1;
    animation: linear 20s animationFramesThree infinite;
  }
  & .features-shape-2 {
    position: absolute;
    right: 190px;
    top: 160px;
    z-index: -1;
    animation: linear 20s animationFramesFive infinite;
  }
  & .features-shape-3 {
    position: absolute;
    right: 160px;
    bottom: 260px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
  }
  &.appie-features-area-5 {
    background: $white;
  }
}

.appie-features-box-item {
  border: 1px solid #202234;
  padding: 25px 30px 30px 70px;
  border-radius: 6px;
  @include transition(0.3s);
  margin-bottom: 20px;
  margin-left: 100px;
  margin-right: 70px;
  @media #{$lg} {
    margin-left: 0;
    margin-right: 0;
  }
  @media #{$md} {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media #{$xs} {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 60px;
  }
  & .title {
    color: $white;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    @media #{$lg} {
      font-size: 20px;
    }
    @media #{$xs} {
      font-size: 20px;
    }
    &::before {
      position: absolute;
      content: "";
      left: -40px;
      top: 0;
      height: 30px;
      width: 30px;
      background: $theme-color-2;
      border: 6px solid #2a182d;
      border-radius: 50%;
    }
  }
  & p {
    font-size: 14px;
    opacity: 0.6;
    color: $white;
    margin-top: 3px;
  }
  &:hover {
    border-color: $theme-color-2;
  }
  &.item-2 {
    & .title {
      &::before {
        background: #2eacfc;
        border-color: #15233d;
      }
    }
    &:hover {
      border-color: #2eacfc;
    }
  }
  &.item-3 {
    & .title {
      &::before {
        background: #33c956;
        border-color: #15262c;
      }
    }
    &:hover {
      border-color: #33c956;
    }
  }
  &.appie-features-box-5-item {
    & .title {
      color: $heading-color;
      &::before {
        border-color: rgba(9, 8, 8, 0.21);
      }
    }
    & p {
      color: $text-color;
      opacity: 1;
    }
  }
}

.appie-features-thumb {
  @media #{$md} {
    text-align: center;
    margin-top: 50px;
  }
  @media #{$xs} {
    text-align: center;
    margin-top: 50px;
  }
}

.appie-features-6-area {
  & .appie-features-tabs-btn {
    & .nav {
      & a {
        &.active {
          color: $theme-color-6;
          border-right-color: $theme-color-6;
          & i {
            background: $theme-color-6;
          }
        }
      }
    }
  }
  & .appie-features-content {
    & > span {
      color: $theme-color-6;
    }
    & a {
      background: $theme-color-6;
      border-color: $theme-color-6;
      &:hover {
        background: $white;
        color: $theme-color-6;
      }
    }
  }
}

.appie-features-9-tabs-content{
  padding-top: 35px;

  @media #{$xs}{
    padding-top: 0px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    color: $text-color;
    padding-bottom: 40px;
  }
}
